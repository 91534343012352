import * as API from '../url';
import axiosInstance, { uploadInstance } from '../axiosInstance';

export const createClient = async (body) => {
  const res = await axiosInstance.post(API.CREATE_CLIENT, body);
  return res.data;
};

export const getClients = async (body) => {
  const res = await axiosInstance.get(API.GET_CLIENTS, body);
  return res.data;
};

export const getClient = async (query) => {
  const res = await axiosInstance.get(API.GET_CLIENT(query));
  return res.data;
};

export const updateClient = async (id, data) => {
  const res = await axiosInstance.put(API.UPDATE_CLIENT(id), data);
  return res.data;
};

export const createProduct = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PRODUCT, body);
  return res.data;
};

export const createInvoice = async (body) => {
  const res = await axiosInstance.post(API.CREATE_INVOICE, body);
  return res.data;
};

export const createSub = async (body) => {
  const res = await axiosInstance.post(API.CREATE_SUB, body);
  return res.data;
};

export const getProducts = async (body) => {
  const res = await axiosInstance.get(API.GET_PRODUCTS, body);
  return res.data;
};

export const getInvoiceSub = async () => {
  const res = await axiosInstance.get(API.GET_INV_SUB);
  return res.data;
};

export const getInvoices = async ({ queryKey }) => {
  const [, status, page, limit] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_INVOICES}?status=${status}&page=${page}&limit=${limit}`
  );
  return res.data;
};

export const getProduct = async (query) => {
  const res = await axiosInstance.get(API.GET_PRODUCT(query));
  return res.data;
};

export const updateProduct = async (id, data) => {
  const res = await axiosInstance.put(API.UPDATE_PRODUCT(id), data);
  return res.data;
};

export const calculateInvoiceTotal = async (body) => {
  const res = await axiosInstance.post(API.CALCULATE_INVOICE_TOTAL, body);
  return res.data;
};

export const updateInvoiceSettings = async (body) => {
  const res = await axiosInstance.patch(API.UPDATE_INVOICE_SETTINGS, body);
  return res.data;
};

export const getInvoiceSettings = async () => {
  const res = await axiosInstance.get(API.GET_INVOICE_SETTINGS);
  return res.data;
};

export const uploadInvoiceLogo = async (body) => {
  const res = await uploadInstance.post(API.UPLOAD_INVOICE_LOGO, body);
  return res.data;
};

export const deleteInvoice = async (query) => {
  const res = await axiosInstance.delete(API.DELETE_INVOICE(query));
  return res.data;
};

export const getInvoice = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${API.GET_INVOICE}/${id}`);
  return res.data;
};

export const updateInvoice = async (id, data) => {
  const res = await axiosInstance.patch(API.UPDATE_INVOICE(id), data);
  return res.data;
};

export const sendInvoice = async (body) => {
  const res = await axiosInstance.post(API.SEND_INVOICE, body);
  return res.data;
};

export const createRecurringInvoice = async (body) => {
  const res = await axiosInstance.post(API.CREATE_RECURRING_INVOICE, body);
  return res.data;
};

export const updateInvoiceStatus = async (id, data) => {
  const res = await axiosInstance.patch(API.UPDATE_INVOICE_STATUS(id), data);
  return res.data;
};

export const getAuditInvoice = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${API.GET_INVOICE_AUDIT}/${id}`);
  return res.data;
};

export const initiateInvoiceOrder = async (body) => {
  const res = await axiosInstance.post(API.INITIATE_INVOICE_ORDER, body);
  return res.data;
};

export const getInvoiceTotalPrice = async (body) => {
  const res = await axiosInstance.post(API.GET_INVOICE_TOTAL_PRICE, body);
  return res.data;
};

export const confirmInvoiceOrder = async (body) => {
  const res = await axiosInstance.post(API.CONFIRM_INVOICE_ORDER, body);
  return res.data;
};
