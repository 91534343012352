import React, { useState } from "react";
import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { activeStyle, businessSetup } from "../../common/constants";
import { NavLink, useNavigate } from "react-router-dom";
import { LogoutIcon, WarnIcon } from "../../common/Images";

import { useLogOut } from "../../../utils/helper";
import useCustomToast from "../../../utils/notifications";
import { IoIosArrowForward, IoIosCheckmarkCircle } from "react-icons/io";

const BuseinssSideBar = ({ flags }) => {
  const logout = useLogOut();
  const navigate = useNavigate();
  const { warningToast } = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);

  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      position={"fixed"}
      zIndex="5"
      w="300px"
      bg="#0F172B"
    >
      <Box h="100vh" overflowY="scroll">
        <Flex
          bg="#0F172B"
          pos="sticky"
          px="24px"
          pt="30px"
          top="0"
          pb="30px"
          justifyContent="space-between"
          align="center"
        >
          <Flex gap="13px" align="center">
            <Image
              src="/assets/dark-logo.png"
              w="160px"
              h="55px"
              objectFit="cover"
            />
          </Flex>
        </Flex>

        <Box pb="25px" borderBottom="1px solid #314169">
          <Text
            mt="25px"
            pl="24px"
            fontSize="14px"
            fontWeight={600}
            color="#fff"
          >
            COMPLETE ACCOUNT SET UP
          </Text>
        </Box>

        <Box mx="20px" mt="20px">
          {businessSetup?.map((item, i) => (
            <Flex
              justifyContent="center"
              flexDir="column"
              key={i}
              mb="20px"
              h="60px"
              _hover={{
                backgroundColor:
                  i === 0
                    ? flags?.isUnregisteredBusinessDetailsSet
                      ? ""
                      : "#D3E0FB"
                    : i === 1
                    ? flags?.isUnregisteredDirectorInfoSet
                      ? ""
                      : "#D3E0FB"
                    : i === 2
                    ? flags?.isUnregisteredBusinessProfileVerified
                      ? ""
                      : "#D3E0FB"
                    : i === 3
                    ? flags?.isUnregisteredBusinessPaymentCompleted
                      ? ""
                      : "#D3E0FB"
                    : i === 4
                    ? flags?.isPinSet
                      ? ""
                      : "#D3E0FB"
                    : "#D3E0FB",
                borderRadius: "10px",
              }}
              onClick={() =>
                navigate(
                  i === 0
                    ? flags?.isUnregisteredBusinessDetailsSet
                      ? null
                      : item?.path
                    : i === 1
                    ? flags?.isUnregisteredDirectorInfoSet
                      ? null
                      : !flags?.isUnregisteredBusinessDetailsSet
                      ? (warningToast("Please Update Business Details"), null)
                      : item?.path
                    : i === 2
                    ? flags?.isUnregisteredBusinessProfileVerified
                      ? null
                      : !flags?.isUnregisteredBusinessDetailsSet
                      ? (warningToast("Please Update Business Details"), null)
                      : !flags?.isUnregisteredDirectorInfoSet
                      ? (warningToast("Please Update Director's Information"),
                        null)
                      : item?.path
                    : i === 3
                    ? flags?.isUnregisteredBusinessPaymentCompleted
                      ? null
                      : !flags?.isUnregisteredBusinessDetailsSet
                      ? (warningToast("Please Update Business Details"), null)
                      : !flags?.isUnregisteredDirectorInfoSet
                      ? (warningToast("Please Update Director's Information"),
                        null)
                      : !flags?.isUnregisteredBusinessProfileVerified
                      ? (warningToast("Please Verify your Profile"), null)
                      : item?.path
                    : i === 4
                    ? flags?.isPinSet
                      ? null
                      : !flags?.isUnregisteredBusinessDetailsSet
                      ? (warningToast("Please Update Business Details"), null)
                      : !flags?.isUnregisteredDirectorInfoSet
                      ? (warningToast("Please Update Director's Information"),
                        null)
                      : !flags?.isUnregisteredBusinessProfileVerified
                      ? (warningToast("Please Verify your Profile"), null)
                      : !flags?.isUnregisteredBusinessPaymentCompleted
                      ? (warningToast("Please complete your payment process"),
                        null)
                      : item?.path
                    : item?.path
                )
              }
            >
              <NavLink
                to={item?.path}
                style={({ isActive }) =>
                  isActive
                    ? { ...activeStyle }
                    : {
                        ...activeStyle,
                        backgroundColor: "",
                        fontWeight: 400,
                        borderRight: "",
                        color: "#8e8e8e",
                        cursor:
                          i === 0
                            ? flags?.isUnregisteredBusinessDetailsSet
                              ? "unset"
                              : "pointer"
                            : i === 1
                            ? flags?.isUnregisteredDirectorInfoSet
                              ? "unset"
                              : "pointer"
                            : i === 2
                            ? flags?.isUnregisteredBusinessProfileVerified
                              ? "unset"
                              : "pointer"
                            : i === 3
                            ? flags?.isUnregisteredBusinessPaymentCompleted
                              ? "unset"
                              : "pointer"
                            : i === 4
                            ? flags?.isPinSet
                              ? "unset"
                              : "pointer"
                            : "pointer",
                      }
                }
              >
                <Flex align="center" justifyContent="space-between" w="full">
                  <Flex
                    align="center"
                    gap="12px"
                    color={
                      i === 0
                        ? flags?.isUnregisteredBusinessDetailsSet
                          ? "green"
                          : "unset"
                        : i === 1
                        ? flags?.isUnregisteredDirectorInfoSet
                          ? "green"
                          : "unset"
                        : i === 2
                        ? flags?.isUnregisteredBusinessProfileVerified
                          ? "green"
                          : "unset"
                        : i === 3
                        ? flags?.isUnregisteredBusinessPaymentCompleted
                          ? "green"
                          : "unset"
                        : i === 4
                        ? flags?.isPinSet
                          ? "green"
                          : "unset"
                        : "unset"
                    }
                  >
                    <Box w="20px" h="20px">
                      {location.pathname === item.path ? (
                        <WarnIcon fill="#0F172B" />
                      ) : i === 0 ? (
                        flags?.isUnregisteredBusinessDetailsSet ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : i === 1 ? (
                        flags?.isUnregisteredDirectorInfoSet ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : i === 2 ? (
                        flags?.isUnregisteredBusinessProfileVerified ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : i === 3 ? (
                        flags?.isUnregisteredBusinessPaymentCompleted ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : i === 4 ? (
                        flags?.isPinSet ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : (
                        <WarnIcon fill="#8E8E8E" />
                      )}
                    </Box>
                    {item.name}
                  </Flex>

                  {i === 0 ? (
                    flags?.isUnregisteredBusinessDetailsSet ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : i === 1 ? (
                    flags?.isUnregisteredDirectorInfoSet ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : i === 2 ? (
                    flags?.isUnregisteredBusinessProfileVerified ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : i === 3 ? (
                    flags?.isUnregisteredBusinessPaymentCompleted ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : i === 4 ? (
                    flags?.isPinSet ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : (
                    <IoIosArrowForward color="#8E8E8E" size="15px" />
                  )}
                </Flex>
              </NavLink>
            </Flex>
          ))}
        </Box>

        <Box
          mt="40px"
          borderTop="1px solid #314169"
          mx="20px"
          fontSize="14px"
          align="center"
          p={2}
          py="30px"
          fontWeight={400}
          cursor="pointer"
          onClick={action}
        >
          {isLoading ? (
            <Flex gap="8px" color="red" align="center">
              <Spinner size="sm" /> Logging Out
            </Flex>
          ) : (
            <Flex gap="8px" align="center" color="#b4b4b4">
              <LogoutIcon fill="#B4B4B4" />
              Log Out
            </Flex>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default BuseinssSideBar;
