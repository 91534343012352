import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import * as API from '../url';
import axiosInstance from '../axiosInstance';
import * as queryKey from '../queryKeys';

import {
  sendPasswordOtp,
  verifyPasswordOtp,
  changePass,
  reSendPasswordOtp,
  registerFreelance,
  ownerDetails,
  getUser,
  utilityBill,
  createPin,
  verifyBvnOtp,
  verifyProfile,
  addSignatories,
  registerBusiness,
  addBusinessOwnerInfo,
  addOtherDirectors,
  createSubAccount,
  createTransactionPin,
  sendBvnOtp,
  verifyBvnPin,
  resendBvnOtp,
  requestCard,
  reSendBvnOtp,
  freezeCard,
  unFreezeCard,
  physicalCard,
  updateUser,
  uploadAvatar,
  getFlags,
  getFlagMutate,
  createVirtualAccount,
  addNoOfSignatories,
  addNoOfDirectors,
  getCardDetails,
  fundDollar,
  fundNaira,
  getFlow,
  getUserAccount,
  getPreviewInvoice,
  deleteAccount,
  getAccountTiers,
  accountTierUpgrade,
} from '../api/account';

export const getCountries = async () => {
  const res = await axios.get(API.COUNTRIES);
  return res.data;
};

export const verifyEmail = async (body) => {
  const res = await axiosInstance.put(API.VERIFY_EMAIL, body);
  return res.data;
};

export const createPassword = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PASS, body);
  return res.data;
};

export const useVerifyProfile = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyProfile, {
    mutationKey: queryKey.VERIFY_PROFILE,
    ...options,
  });
  return { mutate, isLoading };
};

export const useSendPasswordOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendPasswordOtp, {
    mutationKey: queryKey.SEND_PASS_OTP,
    ...options,
  });

  return { mutate, isLoading };
};

export const useReSendPasswordOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(reSendPasswordOtp, {
    mutationKey: 'RESEND_PASS_OTP',
    ...options,
  });

  return { mutate, isLoading };
};

export const useAddSignatories = (options = {}) => {
  const { mutate, isLoading } = useMutation(addSignatories, {
    mutationKey: queryKey.ADD_SIGNATORIES,
    ...options,
  });
  return { mutate, isLoading };
};

export const useRegisterBusiness = (options = {}) => {
  const { mutate, isLoading } = useMutation(registerBusiness, {
    mutationKey: queryKey.REGISTER_BUSINESS,
    ...options,
  });
  return { mutate, isLoading };
};

export const useAddBizOwnerInfo = (options = {}) => {
  const { mutate, isLoading } = useMutation(addBusinessOwnerInfo, {
    mutationKey: queryKey.REGISTER_BUSINESS,
    ...options,
  });
  return { mutate, isLoading };
};

export const useAddOtherDirectors = (options = {}) => {
  const { mutate, isLoading } = useMutation(addOtherDirectors, {
    mutationKey: queryKey.ADD_OTHER_DIRECTORS,
    ...options,
  });
  return { mutate, isLoading };
};

export const useCreateSubAccount = (options = {}) => {
  const { mutate, isLoading } = useMutation(createSubAccount, {
    mutationKey: queryKey.CREATE_SUB_ACCOUNT,
    ...options,
  });
  return { mutate, isLoading };
};

export const useCreateTransactionPin = (options = {}) => {
  const { mutate, isLoading } = useMutation(createTransactionPin, {
    mutationKey: queryKey.CREATE_SUB_ACCOUNT,
    ...options,
  });
  return { mutate, isLoading };
};
export const useUtilityBill = (options = {}) => {
  const { mutate, isLoading } = useMutation(utilityBill, {
    mutationKey: 'UTILITY_BILL',
    ...options,
  });

  return { mutate, isLoading };
};

export const useVerifyPasswordOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyPasswordOtp, {
    mutationKey: 'VERIFY_PASS_OTP',
    ...options,
  });

  return { mutate, isLoading };
};

export const useChangePass = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(changePass, {
    mutationKey: queryKey.CHANGE_PASS,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetUser = (options = {}) => {
  const { data, isLoading, refetch, isError } = useQuery(
    queryKey.GET_USER,
    getUser,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch, isError };
};

export const useGetFlags = (options = {}) => {
  const { data, isLoading, refetch } = useQuery('getFlags', getFlags, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useOwnerDetails = (options = {}) => {
  const { mutate, isLoading } = useMutation(ownerDetails, {
    mutationKey: 'OWNER_DETAILS',
    ...options,
  });

  return { mutate, isLoading };
};

export const useRegisterFreelance = (options = {}) => {
  const { mutate, isLoading } = useMutation(registerFreelance, {
    mutationKey: 'REGISTER_FREELANCE',
    ...options,
  });

  return { mutate, isLoading };
};

export const useSendBvnOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendBvnOtp, {
    mutationKey: 'SEND_BVN_OTP',
    ...options,
  });

  return { mutate, isLoading };
};

export const useVerifyBvnOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyBvnOtp, {
    mutationKey: 'VERIFY_BVN_OTP',
    ...options,
  });

  return { mutate, isLoading };
};

export const useReSendBvnOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(reSendBvnOtp, {
    mutationKey: 'RESEND_BVN_OTP',
    ...options,
  });

  return { mutate, isLoading };
};

export const useResendBvnOpt = (options = {}) => {
  const { mutate, isLoading } = useMutation(resendBvnOtp, {
    mutationKey: queryKey.RESEND_BVN_OPT,
    ...options,
  });
  return { mutate, isLoading };
};

export const useVerifyBvn = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyBvnPin, {
    mutationKey: queryKey.VERIFY_BVN_PIN,
    ...options,
  });
  return { mutate, isLoading };
};

export const useFreezeCard = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(freezeCard, {
    mutationKey: queryKey.FREEZE_CARD,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetCardDetails = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getCardDetails, {
    mutationKey: 'getCardDetails',
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useFundDollar = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(fundDollar, {
    mutationKey: 'fundDollar',
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useFundNaira = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(fundNaira, {
    mutationKey: 'fundNaira',
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useUnFreezeCard = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(unFreezeCard, {
    mutationKey: queryKey.UN_FREEZE_CARD,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useUploadAvatar = (options = {}) => {
  const { mutate, isLoading } = useMutation(uploadAvatar, {
    mutationKey: queryKey.UPLOAD_AVATAR,
    ...options,
  });

  return { mutate, isLoading };
};
export const useUpdateUser = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateUser, {
    mutationKey: queryKey.UPDATE_USER,
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreateVirtualAccount = (options = {}) => {
  const { mutate, isLoading } = useMutation(createVirtualAccount, {
    mutationKey: queryKey.CREATE_VIRTUAL_ACCOUNT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useAddNoOfSignatories = (options = {}) => {
  const { mutate, isLoading } = useMutation(addNoOfSignatories, {
    mutationKey: queryKey.ADD_NO_OF_SIGNATORIES,
    ...options,
  });

  return { mutate, isLoading };
};

export const useAddNoOfDirectors = (options = {}) => {
  const { mutate, isLoading } = useMutation(addNoOfDirectors, {
    mutationKey: queryKey.ADD_NO_OF_DIRECTORS,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetFlow = (duration = '', flow = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PLAN_FLOW, duration, flow],
    getFlow,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetUserAccount = (options = {}) => {
  const { data, isLoading, refetch, isError } = useQuery(
    'useGetUserAccount',
    getUserAccount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch, isError };
};

export const useGetInvoicePreview = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(getPreviewInvoice, {
    mutationKey: 'getInvoicePreview',
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useDeleteAccount = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteAccount, {
    mutationKey: 'deleteAccount',
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetAccountTiers = (options = {}) => {
  const { data, isLoading, refetch, isError } = useQuery(
    'GET_ACCOUNT_TIERS',
    getAccountTiers,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch, isError };
};

export const useAccountTierUpgrade = (options = {}) => {
  const { mutate, isLoading } = useMutation(accountTierUpgrade, {
    mutationKey: 'accountTierUpgrade',
    ...options,
  });
  return { mutate, isLoading };
};
