import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  businessAccountSetup,
  activeStyle,
  businessAccSetupRest,
} from "../../common/constants";
import { NavLink, useNavigate } from "react-router-dom";
import { LogoutIcon, WarnIcon } from "../../common/Images";

import { useLogOut } from "../../../utils/helper";
import useCustomToast from "../../../utils/notifications";
import { IoIosArrowForward, IoIosCheckmarkCircle } from "react-icons/io";
import { useGetFlags, useGetUser } from "../../../services/query/account";

const SetupSideBar = ({ flags }) => {
  const logout = useLogOut();
  const [isLoading, setIsLoading] = useState(false);
  const { warningToast } = useCustomToast();
  const { data: regFlags } = useGetFlags();
  const isSkip = regFlags?.isSignatorySkipped;
  const skipDirector = sessionStorage.getItem("skipDirector");

  const { data } = useGetUser();
  const noOfSignatories = Number(data?.data?.business?.noOfSignatories);
  const isOtherDirectors = regFlags?.canAddOtherDirectors;

  // const noOfDirectors = sessionStorage.getItem("noOfDirectors");
  const filteredBusinessAccountSetup =
    !isOtherDirectors || skipDirector
      ? businessAccountSetup.filter((item) => item.name !== "Director Details")
      : businessAccountSetup;

  const setupSteps = isSkip
    ? filteredBusinessAccountSetup.concat(businessAccSetupRest.slice(-1))
    : filteredBusinessAccountSetup.concat(businessAccSetupRest);

  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };

  const navigate = useNavigate();
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      position={"fixed"}
      zIndex="5"
      w="300px"
      bg="#0F172B"
    >
      <Box h="100vh" overflowY="scroll">
        <Flex
          bg="#0F172B"
          pos="sticky"
          px="24px"
          pt="30px"
          top="0"
          pb="30px"
          justifyContent="space-between"
          align="center"
        >
          <Flex gap="13px" align="center">
            <Image
            src="/assets/dark-logo.png"
            w="160px"
            h="55px"
              objectFit="cover"
            />
          </Flex>
        </Flex>

        <Box pb="25px" borderBottom="1px solid #314169">
          <Text
            mt="25px"
            pl="24px"
            fontSize="14px"
            fontWeight={600}
            color="#fff"
          >
            COMPLETE ACCOUNT SET UP
          </Text>
        </Box>

        <Box mx="20px" mt="20px">
          {setupSteps?.map((item, i) => (
            <Flex
              justifyContent="center"
              flexDir="column"
              key={i}
              mb="20px"
              h="60px"
              _hover={{
                backgroundColor: item?.name?.includes("Details")
                  ? flags?.isBusinessDetailsSet
                    ? ""
                    : "#D3E0FB"
                  : item?.name?.includes("Owner")
                  ? flags?.isBusinessOwnerSet
                    ? ""
                    : "#D3E0FB"
                  : item?.name?.includes("Profile")
                  ? flags?.isBusinessProfileVerified
                    ? ""
                    : "#D3E0FB"
                  : item?.name?.includes("Bvn")
                  ? flags?.isBvnSet
                    ? ""
                    : "#D3E0FB"
                  : item?.name?.includes("Director")
                  ? flags?.isDirectorDetailsSet
                    ? ""
                    : "#D3E0FB"
                  : item?.name?.includes("Signat")
                  ? flags?.isSignatorySet
                    ? ""
                    : "#D3E0FB"
                  : item?.name?.includes("Transac")
                  ? flags?.isPinSet
                    ? ""
                    : "#D3E0FB"
                  : "#D3E0FB",
                borderRadius: "10px",
              }}
              onClick={() =>
                navigate(
                  item?.name?.includes("Business")
                    ? flags?.isBusinessDetailsSet
                      ? null
                      : item?.path
                    : item?.name?.includes("Owner")
                    ? flags?.isBusinessOwnerSet
                      ? null
                      : !flags?.isBusinessDetailsSet
                      ? (warningToast("Please Update Business Information"),
                        null)
                      : item?.path
                    : item?.name?.includes("Profile")
                    ? flags?.isBusinessProfileVerified
                      ? null
                      : !flags?.isBusinessDetailsSet
                      ? (warningToast("Please Update Business Information"),
                        null)
                      : !flags?.isBusinessOwnerSet
                      ? (warningToast("Please Update Business Owner Details"),
                        null)
                      : item?.path
                    : item?.name?.includes("Bvn")
                    ? flags?.isBvnSet
                      ? null
                      : !flags?.isBusinessDetailsSet
                      ? (warningToast("Please Update Business Information"),
                        null)
                      : !flags?.isBusinessOwnerSet
                      ? (warningToast("Please Update Business Owner Details"),
                        null)
                      : !flags?.isBusinessProfileVerified
                      ? (warningToast("Please Update Profile Record"), null)
                      : item?.path
                    : item?.name?.includes("Director")
                    ? flags?.isDirectorDetailsSet
                      ? null
                      : !flags?.isBusinessDetailsSet
                      ? (warningToast("Please Update Business Information"),
                        null)
                      : !flags?.isBusinessOwnerSet
                      ? (warningToast("Please Update Business Owner Details"),
                        null)
                      : !flags?.isBusinessProfileVerified
                      ? (warningToast("Please Update Profile Record"), null)
                      : !flags?.isBvnSet
                      ? (warningToast("Please Update BVN record"), null)
                      : item?.path
                    : item?.name?.includes("Signat")
                    ? flags?.isSignatorySet
                      ? null
                      : !flags?.isBusinessDetailsSet
                      ? (warningToast("Please Update Business Information"),
                        null)
                      : !flags?.isBusinessOwnerSet
                      ? (warningToast("Please Update Business Owner Details"),
                        null)
                      : !flags?.isBusinessProfileVerified
                      ? (warningToast("Please Update Profile Record"), null)
                      : !flags?.isBvnSet
                      ? (warningToast("Please Update BVN record"), null)
                      : flags?.canAddOtherDirectors &&
                        !flags?.isDirectorDetailsSet
                      ? (warningToast("Please Update Director Details"), null)
                      : item?.path
                    : item?.name?.includes("Transac")
                    ? flags?.isPinSet
                      ? null
                      : !flags?.isBusinessDetailsSet
                      ? (warningToast("Please Update Business Information"),
                        null)
                      : !flags?.isBusinessOwnerSet
                      ? (warningToast("Please Update Business Owner Details"),
                        null)
                      : !flags?.isBusinessProfileVerified
                      ? (warningToast("Please Update Profile Record"), null)
                      : !flags?.isBvnSet
                      ? (warningToast("Please Update BVN record"), null)
                      : flags?.canAddOtherDirectors &&
                        !flags?.isDirectorDetailsSet
                      ? (warningToast("Please Update Director Details"), null)
                      : !flags?.isSignatorySet
                      ? (warningToast("Please Update Signatory"), null)
                      : item?.path
                    : item?.path,
                )
              }
            >
              <NavLink
                to={item?.path}
                style={({ isActive }) =>
                  isActive
                    ? { ...activeStyle }
                    : {
                        ...activeStyle,
                        backgroundColor: "",
                        fontWeight: 400,
                        borderRight: "",
                        color: "#8e8e8e",
                        cursor: item?.name?.includes("Details")
                          ? flags?.isBusinessDetailsSet
                            ? "unset"
                            : "pointer"
                          : item?.name?.includes("Owner")
                          ? flags?.isBusinessOwnerSet
                            ? "unset"
                            : "pointer"
                          : item?.name?.includes("Profile")
                          ? flags?.isBusinessProfileVerified
                            ? "unset"
                            : "pointer"
                          : item?.name?.includes("Bvn")
                          ? flags?.isBvnSet
                            ? "unset"
                            : "pointer"
                          : item?.name?.includes("Director")
                          ? flags?.isDirectorDetailsSet
                            ? "unset"
                            : "pointer"
                          : item?.name?.includes("Signat")
                          ? flags?.isSignatorySet
                            ? "unset"
                            : "pointer"
                          : item?.name?.includes("Transac")
                          ? flags?.isPinSet
                            ? "unset"
                            : "pointer"
                          : "pointer",
                      }
                }
              >
                <Flex align="center" justifyContent="space-between" w="full">
                  <Flex
                    align="center"
                    gap="12px"
                    color={
                      item?.name?.includes("Business")
                        ? flags?.isBusinessDetailsSet
                          ? "green"
                          : "unset"
                        : item?.name?.includes("Owner")
                        ? flags?.isBusinessOwnerSet
                          ? "green"
                          : "unset"
                        : item?.name?.includes("Profile")
                        ? flags?.isBusinessProfileVerified
                          ? "green"
                          : "unset"
                        : item?.name?.includes("Bvn")
                        ? flags?.isBvnSet
                          ? "green"
                          : "unset"
                        : item?.name?.includes("Director")
                        ? flags?.isDirectorDetailsSet
                          ? "green"
                          : "unset"
                        : item?.name?.includes("Signat")
                        ? flags?.isSignatorySet || noOfSignatories > 0
                          ? "green"
                          : "unset"
                        : item?.name?.includes("Transac")
                        ? flags?.isPinSet
                          ? "green"
                          : "unset"
                        : "green"
                    }
                  >
                    <Box w="20px" h="20px">
                      {location.pathname === item.path ? (
                        <WarnIcon fill="#0F172B" />
                      ) : item?.name?.includes("Business") ? (
                        flags?.isBusinessDetailsSet ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : item?.name?.includes("Owner") ? (
                        flags?.isBusinessOwnerSet ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : item?.name?.includes("Profile") ? (
                        flags?.isBusinessProfileVerified ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : item?.name?.includes("Bvn") ? (
                        flags?.isBvnSet ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : item?.name?.includes("Director") ? (
                        flags?.isDirectorDetailsSet ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : item?.name?.includes("Signat") ? (
                        flags?.isSignatorySet ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : item?.name?.includes("Transac") ? (
                        flags?.isPinSet ? (
                          <IoIosCheckmarkCircle size="24px" color="green" />
                        ) : (
                          <WarnIcon fill="#8E8E8E" />
                        )
                      ) : (
                        <WarnIcon fill="#8E8E8E" />
                      )}
                    </Box>
                    {item.name}
                  </Flex>

                  {item?.name?.includes("Business") ? (
                    flags?.isBusinessDetailsSet ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : item?.name?.includes("Owner") ? (
                    flags?.isBusinessOwnerSet ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : item?.name?.includes("Profile") ? (
                    flags?.isBusinessProfileVerified ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : item?.name?.includes("Bvn") ? (
                    flags?.isBvnSet ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : item?.name?.includes("Director") ? (
                    flags?.isDirectorDetailsSet ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : item?.name?.includes("Signat") ? (
                    flags?.isSignatorySet ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : item?.name?.includes("Transac") ? (
                    flags?.isPinSet ? (
                      ""
                    ) : (
                      <IoIosArrowForward color="#8E8E8E" size="15px" />
                    )
                  ) : (
                    <IoIosArrowForward color="#8E8E8E" size="15px" />
                  )}
                </Flex>
              </NavLink>
            </Flex>
          ))}
        </Box>

        <Box
          mt="40px"
          borderTop="1px solid #314169"
          mx="20px"
          fontSize="14px"
          align="center"
          p={2}
          py="30px"
          fontWeight={400}
          cursor="pointer"
          onClick={action}
        >
          {isLoading ? (
            <Flex gap="8px" color="red" align="center">
              <Spinner size="sm" /> Logging Out
            </Flex>
          ) : (
            <Flex gap="8px" align="center" color="#b4b4b4">
              <LogoutIcon fill="#B4B4B4" />
              Log Out
            </Flex>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default SetupSideBar;
