import { lazy } from 'react';
import WithSuspense from '../components/Loaders/WithSuspense';
import { PRIVATE_PATHS, PUBLIC_PATHS } from './constants';
import { Navigate } from 'react-router-dom';
import DetailCards from '../components/data/PaymentLink/DetailCards';
import TransactionDetails from '../pages/PaymentLink/TransactionDetails';

const {
  LOGIN,
  REG_ONE,
  REG_TWO,
  FORGOT_PASS,
  VERIFY_PASS_OTP,
  RESET_PASS,
  VERIFY_EMAIL,
  INVOICE_PREVIEW,
  INVOICE_PAYMENT,
  PAYMENT_LINK_PREVIEW,
  PAYMENT_LINK_REVIEW,
  CONFIRM_PAYMENT,
  CONFIRM_INVOICE_PAYMENT,
  INVOICE_PAYMENT_REVIEW,
} = PUBLIC_PATHS;

const {
  BUSINESS_BUSINESS_DETAILS,
  BUSINESS_DIRECTOR_INFO,
  BUSINESS_PAYMENT,
  BUSINESS_PIN,
  BUSINESS_PROFILE_VERIFICATION,

  PENDING_APPROVAL,

  LLC_BUSINESS_DETAILS,
  LLC_DIRECTOR_INFO,
  LLC_PAYMENT,
  LLC_PIN,
  LLC_SECRETARY,
  LLC_STATEMENT,
  LLC_WITNESS,

  NGO_DETAILS,
  NGO_TRUSTEE_INFO,
  NGO_STATEMENT,
  NGO_SECRETARY,
  NGO_WITNESS,
  NGO_PAYMENT,
  NGO_PIN,

  DASHBOARD,
  BUSINESS_DETAILS,
  BUSINESS_OWNERSHIP,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_STEP_2,
  PROFILE_VERIFICATION,
  BVN_VERIFICATION,
  DIRECTOR_DETAILS,
  SIGNATORIES,
  TRANSACTION_PIN,
  INVITE_SIGNATORIES,
  ACCOUNT,
  CARDS_MANAGEMENT,
  EXPENSE_MANAGEMENT,
  POS_MANAGEMENT,
  STAFF_MANAGEMENT,
  PHYSICAL_POS,
  REQUEST_POS,
  VIRTUAL_POS,
  INVOICE,
  CREATE_INVOICE,
  SAVE_INVOICE,
  INVOICE_DRAFT,
  VIEW_INVOICE,
  EDIT_DRAFT,
  SUBSCRIPTION,
  INVOICE_SETTINGS,
  SAVINGS,
  SETTINGS,
  PERSONAL_INFO,
  NOTI_PREFERENCE,
  SECURITY,
  LANGUAGES,
  PRIVACY,
  ACCOUNT_UPGRADE,
  METER_DETAILS,
  SUPPORT,
  PAYMENTS,
  REVIEW_PAYMENT_TRANSFER,
  MAKE_PAYMENT_TRANSFER,
  BTW,
  SINGLE,
  EMAIL,
  PENDING_PAYMENTS,
  PAY_BILLS,
  REVIEW_PAYMENT,
  PAY_BILLS_AIRTIME,
  MAKE_PAYMENT,
  PAY_BILLS_DATA,
  PAY_BILLS_ELECTRICITY,
  PAY_BILLS_CABLE,
  NOTIFICATIONS,
  TRANSACTIONS,
  DELETE_ACCOUNT,

  FREELANCER_BVN_VERIFICATION,
  UTILITY_BILL,
  FREELANCER_TRANSACTION_PIN,
  FREELANCER_DETAILS,
  FREELANCER_OWNERSHIP,
  GLOBAL_ACCOUNT,
  GLOBAL_ACCOUNT_SIGN_UP,
  SEND_MONEY,
  GLOBAL_ACC_KYC,
  GLOBAL_ACC_KYC_STEP_2,
  GLOBAL_ACC_MORE_INFO,
  GLOBAL_ACC_VIEW_TRANSACTIONS,

  PAYMENT_LINK,
  PAYMENT_LINK_SETTINGS,
  VIEW_PAYMENT_LINK,
  TRANSACTION_DETAILS,
  UTILITY_BILL_DETAILS,
} = PRIVATE_PATHS;

const FreelancerDetails = WithSuspense(
  lazy(() => import('../pages/FreelancerSetup/MyDetails'))
);

const FreelancerUtilityBill = WithSuspense(
  lazy(() => import('../pages/FreelancerSetup/UtiltyBill'))
);

const FreelancerPin = WithSuspense(
  lazy(() => import('../pages/FreelancerSetup/TransactionPin'))
);

const FreelancerBvn = WithSuspense(
  lazy(() => import('../pages/FreelancerSetup/BvnVerification'))
);

const FreelancerOwnership = WithSuspense(
  lazy(() => import('../pages/FreelancerSetup/BusinessOwnership'))
);

const Login = WithSuspense(lazy(() => import('../pages/Authentication/Login')));

const ResetPassword = WithSuspense(
  lazy(() => import('../pages/Authentication/ResetPassword'))
);

const ForgotPassword = WithSuspense(
  lazy(() => import('../pages/Authentication/ForgotPassword'))
);

const VerifyCodePass = WithSuspense(
  lazy(() => import('../pages/Authentication/VerifyCodePass'))
);

const VerifyEmail = WithSuspense(
  lazy(() => import('../pages/Authentication/VerifyEmail'))
);

const RegisterOne = WithSuspense(
  lazy(() => import('../pages/Authentication/RegisterOne'))
);

const RegisterTwo = WithSuspense(
  lazy(() => import('../pages/Authentication/RegisterTwo'))
);

const InvoicePreview = WithSuspense(
  lazy(() => import('../pages/Authentication/InvoicePreview'))
);

const CreateAccount = WithSuspense(
  lazy(() => import('../pages/AccountSetup/CreateAccount'))
);

const CreateAccountStep2 = WithSuspense(
  lazy(() => import('../pages/AccountSetup/CreateAccountStep2'))
);

const Dashboard = WithSuspense(
  lazy(() => import('../pages/Dashboard/Dashboard'))
);

const BusinessDetails = WithSuspense(
  lazy(() => import('../pages/AccountSetup/BusinessDetails'))
);

const BusinessOwnership = WithSuspense(
  lazy(() => import('../pages/AccountSetup/BusinessOwnership'))
);

const ProfileVerification = WithSuspense(
  lazy(() => import('../pages/AccountSetup/ProfileVerification'))
);

const BvnVerification = WithSuspense(
  lazy(() => import('../pages/AccountSetup/BvnVerification'))
);

const Signatories = WithSuspense(
  lazy(() => import('../pages/AccountSetup/Signatories'))
);

const InviteSignatories = WithSuspense(
  lazy(() => import('../pages/AccountSetup/InviteSignatories'))
);
const TransactionPin = WithSuspense(
  lazy(() => import('../pages/AccountSetup/TransactionPin'))
);

const DirectorDetails = WithSuspense(
  lazy(() => import('../pages/AccountSetup/DirectorDetails'))
);

const Account = WithSuspense(lazy(() => import('../pages/Account/Account')));

const CardsManagement = WithSuspense(
  lazy(() => import('../pages/CardsManagement/CardsManagement'))
);
const ExpenseManagement = WithSuspense(
  lazy(() => import('../pages/ExpenseManagement/ExpenseManagement'))
);

const Invoice = WithSuspense(lazy(() => import('../pages/Invoice/Invoice')));

const CreateInvoice = WithSuspense(
  lazy(() => import('../pages/Invoice/CreateInvoice'))
);

const SaveInvoice = WithSuspense(
  lazy(() => import('../pages/Invoice/SaveInvoice'))
);

const InvoiceDraft = WithSuspense(
  lazy(() => import('../pages/Invoice/InvoiceDraft'))
);

const EditDraft = WithSuspense(
  lazy(() => import('../pages/Invoice/EditDraft'))
);

const Subscription = WithSuspense(
  lazy(() => import('../pages/Invoice/Subscription'))
);

const InvoiceSetting = WithSuspense(
  lazy(() => import('../pages/Invoice/InvoiceSettings'))
);

const InvoiceView = WithSuspense(
  lazy(() => import('../pages/Invoice/ViewInvoice'))
);

const Notifications = WithSuspense(
  lazy(() => import('../pages/Notifications/Notifications'))
);

const Payments = WithSuspense(
  lazy(() => import('../pages/Payments/Transfer/Transfer'))
);

const Single = WithSuspense(
  lazy(() => import('../pages/Payments/Transfer/Single'))
);

const Btw = WithSuspense(lazy(() => import('../pages/Payments/Transfer/Btw')));

const EmailTransfer = WithSuspense(
  lazy(() => import('../pages/Payments/Transfer/EmailTransfer'))
);

const ReviewPaymentTransfer = WithSuspense(
  lazy(() => import('../pages/Payments/Transfer/ReviewPayment'))
);

const MakePaymentTransfer = WithSuspense(
  lazy(() => import('../pages/Payments/Transfer/MakePayment'))
);

const PayBills = WithSuspense(
  lazy(() => import('../pages/Payments/Bills/PayBills'))
);
const ReviewPayment = WithSuspense(
  lazy(() => import('../pages/Payments/Bills/ReviewPayment'))
);
const MakePayment = WithSuspense(
  lazy(() => import('../pages/Payments/Bills/MakePayment'))
);
const PayBillsAirtime = WithSuspense(
  lazy(() => import('../pages/Payments/Bills/Airtime'))
);
const PayBillsData = WithSuspense(
  lazy(() => import('../pages/Payments/Bills/Data'))
);
const PayBillsElectricity = WithSuspense(
  lazy(() => import('../pages/Payments/Bills/Electiricity'))
);
const PayBillsCable = WithSuspense(
  lazy(() => import('../pages/Payments/Bills/Cable'))
);

const PendingPayment = WithSuspense(
  lazy(() => import('../pages/Payments/PendingPayment'))
);

const PosManagement = WithSuspense(
  lazy(() => import('../pages/PosManagement/PosManagement'))
);

const StaffManagement = WithSuspense(
  lazy(() => import('../pages/PosManagement/StaffManagement'))
);

const VirtualPos = WithSuspense(
  lazy(() => import('../pages/PosManagement/VirtualPos'))
);

const PhysicalPos = WithSuspense(
  lazy(() => import('../pages/PosManagement/PhysicalPos'))
);

const RequestPos = WithSuspense(
  lazy(() => import('../pages/PosManagement/RequestPos'))
);

const Savings = WithSuspense(lazy(() => import('../pages/Savings/Savings')));

const Settings = WithSuspense(lazy(() => import('../pages/Settings/Settings')));

const PersonalInfo = WithSuspense(
  lazy(() => import('../pages/Settings/PersonalInfo'))
);

const NotiPreference = WithSuspense(
  lazy(() => import('../pages/Settings/NotiPreference'))
);

const DeleteAccount = WithSuspense(
  lazy(() => import('../pages/Settings/DeleteAccount'))
);

const Security = WithSuspense(lazy(() => import('../pages/Settings/Security')));

const Languages = WithSuspense(
  lazy(() => import('../pages/Settings/Languages'))
);
const Privacy = WithSuspense(lazy(() => import('../pages/Settings/Privacy')));

const Support = WithSuspense(lazy(() => import('../pages/Settings/Support')));
const AccountUpgrade = WithSuspense(
  lazy(() => import('../pages/Settings/AccountUpgrade'))
);

const Transactions = WithSuspense(
  lazy(() => import('../pages/Transactions/Transactions'))
);

const BusinessBusinessDetails = WithSuspense(
  lazy(() => import('../pages/BusinessSetup/BusinessDetails'))
);

const BusinessDirectorInfo = WithSuspense(
  lazy(() => import('../pages/BusinessSetup/DirectorInfo'))
);

const BusinessProfileVerification = WithSuspense(
  lazy(() => import('../pages/BusinessSetup/ProfileVerification'))
);

const BusinessPayment = WithSuspense(
  lazy(() => import('../pages/BusinessSetup/Payment'))
);

const BusinessPin = WithSuspense(
  lazy(() => import('../pages/BusinessSetup/CreatePin'))
);

const PendingApproval = WithSuspense(
  lazy(() => import('../pages/PendingApproval/PendingApproval'))
);

const LLCPin = WithSuspense(lazy(() => import('../pages/LLCSetup/CreatePin')));

const LLCBusinessDetailsDetails = WithSuspense(
  lazy(() => import('../pages/LLCSetup/BusinessDetails'))
);

const LLCDirectorInfo = WithSuspense(
  lazy(() => import('../pages/LLCSetup/DirectorInfo'))
);

const LLCPayment = WithSuspense(
  lazy(() => import('../pages/LLCSetup/Payment'))
);

const LLCSecretary = WithSuspense(
  lazy(() => import('../pages/LLCSetup/Secretary'))
);

const LLCWitness = WithSuspense(
  lazy(() => import('../pages/LLCSetup/Witness'))
);

const LLCStatement = WithSuspense(
  lazy(() => import('../pages/LLCSetup/Statement'))
);

const NGOPin = WithSuspense(lazy(() => import('../pages/NGOSetup/CreatePin')));

const NGODetails = WithSuspense(
  lazy(() => import('../pages/NGOSetup/NgoDetails'))
);

const NGOTrusteeInfo = WithSuspense(
  lazy(() => import('../pages/NGOSetup/TrusteeInfo'))
);

const NGOPayment = WithSuspense(
  lazy(() => import('../pages/NGOSetup/Payment'))
);

const NGOSecretary = WithSuspense(
  lazy(() => import('../pages/NGOSetup/Secretary'))
);

const NGOWitness = WithSuspense(
  lazy(() => import('../pages/NGOSetup/Witness'))
);

const NGOStatement = WithSuspense(
  lazy(() => import('../pages/NGOSetup/Statement'))
);

const GlobalAccount = WithSuspense(
  lazy(() => import('../pages/GlobalAccount/GlobalAccount'))
);

const GlobalAccountSignUp = WithSuspense(
  lazy(() =>
    import('../pages/GlobalAccount/GlobalAccountSetup/GlobalAccountSignUp')
  )
);

const SendMoney = WithSuspense(
  lazy(() => import('../pages/GlobalAccount/Send'))
);

const VerifyKyc = WithSuspense(
  lazy(() => import('../pages/GlobalAccount/GlobalAccountSetup/GlobalAccKyc'))
);

const VerifyKycStep2 = WithSuspense(
  lazy(() =>
    import('../pages/GlobalAccount/GlobalAccountSetup/GlobalAccKycStep2')
  )
);

const GlobalAccViewMore = WithSuspense(
  lazy(() => import('../pages/GlobalAccount/ViewMoreInfo'))
);

const GlobalAccViewTransactions = WithSuspense(
  lazy(() => import('../pages/GlobalAccount/ViewTransactions.js'))
);

const PaymentLink = WithSuspense(
  lazy(() => import('../pages/PaymentLink/PaymentLink'))
);

const PaymentLinkSettings = WithSuspense(
  lazy(() => import('../pages/PaymentLink/Settings'))
);

const ViewPaymentLink = WithSuspense(
  lazy(() => import('../pages/PaymentLink/ViewPaymentLink'))
);

const PaymentLinkPreview = WithSuspense(
  lazy(() => import('../pages/Authentication/PaymentLink/PaymentLinkPreview'))
);

const PaymentLinkReview = WithSuspense(
  lazy(() => import('../pages/Authentication/PaymentLink/PaymentReview'))
);

const ConfirmPayment = WithSuspense(
  lazy(() => import('../pages/Authentication/PaymentLink/ConfirmPayment'))
);

const ConfirmInvoiceOrder = WithSuspense(
  lazy(() => import('../pages/Authentication/ConfirmInvoiceOrder'))
);

const InvoicePaymentReview = WithSuspense(
  lazy(() => import('../pages/Authentication/InvoicePaymentReview'))
);

const MeterDetails = WithSuspense(
  lazy(() => import('../pages/Settings/MeterDetails'))
);

const UtilityBillDetails = WithSuspense(
  lazy(() => import('../pages/Settings/Utility'))
);

export const PUBLIC_ROUTES = [
  { path: LOGIN, element: <Login /> },
  { path: FORGOT_PASS, element: <ForgotPassword /> },
  { path: VERIFY_PASS_OTP, element: <VerifyCodePass /> },
  { path: VERIFY_EMAIL, element: <VerifyEmail /> },
  { path: RESET_PASS, element: <ResetPassword /> },
  { path: REG_ONE, element: <RegisterOne /> },
  { path: REG_TWO, element: <RegisterTwo /> },
  { path: INVOICE_PREVIEW, element: <InvoicePreview /> },
  { path: PAYMENT_LINK_PREVIEW, element: <PaymentLinkPreview /> },
  { path: PAYMENT_LINK_REVIEW, element: <PaymentLinkReview /> },
  { path: CONFIRM_PAYMENT, element: <ConfirmPayment /> },
  { path: CONFIRM_INVOICE_PAYMENT, element: <ConfirmInvoiceOrder /> },
  { path: INVOICE_PAYMENT_REVIEW, element: <InvoicePaymentReview /> },
  { path: '*', element: <Navigate to="/login" replace /> },
];

export const PRIVATE_ROUTES = [
  { path: BUSINESS_BUSINESS_DETAILS, element: <BusinessBusinessDetails /> },
  { path: BUSINESS_DIRECTOR_INFO, element: <BusinessDirectorInfo /> },
  {
    path: BUSINESS_PROFILE_VERIFICATION,
    element: <BusinessProfileVerification />,
  },
  { path: BUSINESS_PAYMENT, element: <BusinessPayment /> },
  { path: BUSINESS_PIN, element: <BusinessPin /> },
  { path: PENDING_APPROVAL, element: <PendingApproval /> },

  { path: LLC_PIN, element: <LLCPin /> },
  { path: LLC_PAYMENT, element: <LLCPayment /> },
  { path: LLC_DIRECTOR_INFO, element: <LLCDirectorInfo /> },
  { path: LLC_BUSINESS_DETAILS, element: <LLCBusinessDetailsDetails /> },
  { path: LLC_SECRETARY, element: <LLCSecretary /> },
  { path: LLC_WITNESS, element: <LLCWitness /> },
  { path: LLC_STATEMENT, element: <LLCStatement /> },

  { path: NGO_PIN, element: <NGOPin /> },
  { path: NGO_PAYMENT, element: <NGOPayment /> },
  { path: NGO_TRUSTEE_INFO, element: <NGOTrusteeInfo /> },
  { path: NGO_DETAILS, element: <NGODetails /> },
  { path: NGO_SECRETARY, element: <NGOSecretary /> },
  { path: NGO_WITNESS, element: <NGOWitness /> },
  { path: NGO_STATEMENT, element: <NGOStatement /> },

  { path: DASHBOARD, element: <Dashboard /> },
  { path: CREATE_ACCOUNT, element: <CreateAccount /> },
  { path: CREATE_ACCOUNT_STEP_2, element: <CreateAccountStep2 /> },
  { path: BUSINESS_DETAILS, element: <BusinessDetails /> },
  { path: BUSINESS_OWNERSHIP, element: <BusinessOwnership /> },
  { path: PROFILE_VERIFICATION, element: <ProfileVerification /> },
  { path: BVN_VERIFICATION, element: <BvnVerification /> },
  { path: SIGNATORIES, element: <Signatories /> },
  { path: INVITE_SIGNATORIES, element: <InviteSignatories /> },
  { path: DIRECTOR_DETAILS, element: <DirectorDetails /> },
  { path: TRANSACTION_PIN, element: <TransactionPin /> },
  { path: ACCOUNT, element: <Account /> },
  { path: CARDS_MANAGEMENT, element: <CardsManagement /> },
  { path: EXPENSE_MANAGEMENT, element: <ExpenseManagement /> },
  { path: POS_MANAGEMENT, element: <PosManagement /> },
  { path: STAFF_MANAGEMENT, element: <StaffManagement /> },
  { path: VIRTUAL_POS, element: <VirtualPos /> },
  { path: PHYSICAL_POS, element: <PhysicalPos /> },
  { path: REQUEST_POS, element: <RequestPos /> },
  { path: INVOICE, element: <Invoice /> },
  { path: CREATE_INVOICE, element: <CreateInvoice /> },
  { path: SAVE_INVOICE, element: <SaveInvoice /> },
  { path: INVOICE_DRAFT, element: <InvoiceDraft /> },
  { path: EDIT_DRAFT, element: <EditDraft /> },
  { path: SUBSCRIPTION, element: <Subscription /> },
  { path: INVOICE_SETTINGS, element: <InvoiceSetting /> },
  { path: VIEW_INVOICE, element: <InvoiceView /> },
  { path: SAVINGS, element: <Savings /> },
  { path: PAYMENTS, element: <Payments /> },
  { path: SINGLE, element: <Single /> },
  { path: BTW, element: <Btw /> },
  { path: EMAIL, element: <EmailTransfer /> },
  { path: REVIEW_PAYMENT_TRANSFER, element: <ReviewPaymentTransfer /> },
  { path: MAKE_PAYMENT_TRANSFER, element: <MakePaymentTransfer /> },
  { path: PENDING_PAYMENTS, element: <PendingPayment /> },
  { path: PAY_BILLS, element: <PayBills /> },
  { path: PAY_BILLS_AIRTIME, element: <PayBillsAirtime /> },
  { path: PAY_BILLS_DATA, element: <PayBillsData /> },
  { path: PAY_BILLS_ELECTRICITY, element: <PayBillsElectricity /> },
  { path: PAY_BILLS_CABLE, element: <PayBillsCable /> },
  { path: REVIEW_PAYMENT, element: <ReviewPayment /> },
  { path: MAKE_PAYMENT, element: <MakePayment /> },
  { path: SETTINGS, element: <Settings /> },
  { path: PERSONAL_INFO, element: <PersonalInfo /> },
  { path: NOTI_PREFERENCE, element: <NotiPreference /> },
  { path: SECURITY, element: <Security /> },
  { path: LANGUAGES, element: <Languages /> },
  { path: PRIVACY, element: <Privacy /> },
  { path: SUPPORT, element: <Support /> },
  { path: ACCOUNT_UPGRADE, element: <AccountUpgrade /> },
  { path: DELETE_ACCOUNT, element: <DeleteAccount /> },
  { path: NOTIFICATIONS, element: <Notifications /> },
  { path: TRANSACTIONS, element: <Transactions /> },
  { path: FREELANCER_BVN_VERIFICATION, element: <FreelancerBvn /> },
  { path: FREELANCER_DETAILS, element: <FreelancerDetails /> },
  { path: FREELANCER_OWNERSHIP, element: <FreelancerOwnership /> },
  { path: FREELANCER_TRANSACTION_PIN, element: <FreelancerPin /> },
  { path: UTILITY_BILL, element: <FreelancerUtilityBill /> },

  { path: GLOBAL_ACCOUNT, element: <GlobalAccount /> },
  { path: GLOBAL_ACCOUNT_SIGN_UP, element: <GlobalAccountSignUp /> },

  { path: SEND_MONEY, element: <SendMoney /> },
  { path: GLOBAL_ACC_KYC, element: <VerifyKyc /> },
  { path: GLOBAL_ACC_KYC_STEP_2, element: <VerifyKycStep2 /> },
  { path: GLOBAL_ACC_MORE_INFO, element: <GlobalAccViewMore /> },
  {
    path: GLOBAL_ACC_VIEW_TRANSACTIONS,
    element: <GlobalAccViewTransactions />,
  },

  { path: PAYMENT_LINK, element: <PaymentLink /> },

  { path: PAYMENT_LINK_SETTINGS, element: <PaymentLinkSettings /> },

  { path: VIEW_PAYMENT_LINK, element: <ViewPaymentLink /> },

  { path: TRANSACTION_DETAILS, element: <TransactionDetails /> },

  { path: METER_DETAILS, element: <MeterDetails /> },

  { path: UTILITY_BILL_DETAILS, element: <UtilityBillDetails /> },

  { path: '*', element: <Navigate to="/account" replace /> },
];
