import * as API from '../url';
import axiosInstance, { uploadInstance } from '../axiosInstance';

export const getRecentTransactions = async ({ queryKey }) => {
  const [, page, limit, startDate, endDate, type] = queryKey;

  let query = '';

  if (page) {
    query = query + `page=${page}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (startDate) {
    query = query + `startDate=${startDate}&`;
  }
  if (endDate) {
    query = query + `endDate=${endDate}&`;
  }
  if (type) {
    query = query + `type=${type}&`;
  }
  const res = await axiosInstance.get(
    `${API.GET_RECENT_TRANSACTIONS}?${query}`
  );
  return res.data;
};

export const getAllTransactions = async ({ queryKey }) => {
  const [
    ,
    id,
    page,
    limit,
    startDate,
    endDate,
    type,
    category,
    reference,
    status,
  ] = queryKey;

  let query = '';

  if (page) {
    query = query + `page=${page}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (startDate) {
    query = query + `startDate=${startDate}&`;
  }
  if (endDate) {
    query = query + `endDate=${endDate}&`;
  }
  if (type) {
    query = query + `type=${type}&`;
  }
  if (category) {
    query = query + `category=${category}&`;
  }
  if (reference) {
    query = query + `reference=${reference}&`;
  }
  if (status) {
    query = query + `status=${status}`;
  }
  const res = await axiosInstance.get(
    `${API.GET_ALL_TRANSACTIONS}/${id}?${query}`
  );
  return res.data;
};
