import React, { useEffect } from 'react';
import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import SideBar from './AuthLayout/SideBar';
import SetupSideBar from './AuthLayout/SetupSideBar';
import FreelancerSideBar from './AuthLayout/FreelancerSideBar';
import SideBarr from './NonAuthLayout/SideBarr';
import Header from './AuthLayout/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetFlags, useGetUser } from '../../services/query/account';
import BuseinssSideBar from './AuthLayout/BuseinssSideBar';
import LLCSideBar from './AuthLayout/LLCSideBar';
import NGOSideBar from './AuthLayout/NGOSideBar';
import { MdArrowBack } from 'react-icons/md';
import { IoIosArrowBack } from 'react-icons/io';

export const AuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 1226px)');
  const [isPhone] = useMediaQuery('(max-width: 996px)');
  const { pathname } = useLocation();
  const { data: user } = useGetUser();
  const account_type = sessionStorage.getItem('account_type');
  const bus_account_type = sessionStorage.getItem('bus_account_type');
  const { data: flags, refetch } = useGetFlags();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [pathname]);

  useEffect(() => {
    const events = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ];
    const resetIdleTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        sessionStorage.clear();
        window.location.href = '/login';
      }, 420000);
    };
    events.forEach((event) => window.addEventListener(event, resetIdleTimer));

    let timeoutId = setTimeout(() => {
      sessionStorage.clear();
      window.location.href = '/login';
    }, 420000);

    return () => {
      clearTimeout(timeoutId);
      events.forEach((event) =>
        window.removeEventListener(event, resetIdleTimer)
      );
    };
  }, []);

  const ngoChecks =
    flags?.isUnregisteredBusinessDetailsSet &&
    flags?.isUnregisteredDirectorInfoSet &&
    flags?.isShareAlloted &&
    flags?.isSecretaryAdded &&
    flags?.isWitnessAdded &&
    flags?.isUnregisteredBusinessPaymentCompleted &&
    flags?.isPinSet &&
    user?.data?.businessRegistration?.status !== 'IN_REVIEW';

  const llcChecks =
    flags?.isUnregisteredBusinessDetailsSet &&
    flags?.isUnregisteredDirectorInfoSet &&
    flags?.isShareAlloted &&
    flags?.isSecretaryAdded &&
    flags?.isWitnessAdded &&
    flags?.isUnregisteredBusinessPaymentCompleted &&
    flags?.isPinSet &&
    user?.data?.businessRegistration?.status !== 'IN_REVIEW';

  const unregBusCheck =
    flags?.isUnregisteredBusinessDetailsSet &&
    flags?.isUnregisteredDirectorInfoSet &&
    flags?.isUnregisteredBusinessProfileVerified &&
    flags?.isUnregisteredBusinessPaymentCompleted &&
    flags?.isPinSet &&
    user?.data?.businessRegistration?.status !== 'IN_REVIEW';

  const freelancerChecks =
    flags?.isBusinessDetailsSet && flags?.isBvnSet && flags?.isPinSet;

  const regCheck =
    flags?.isBusinessDetailsSet &&
    flags?.isBusinessOwnerSet &&
    flags?.isBusinessProfileVerified &&
    flags?.isBvnSet &&
    (flags?.canAddOtherDirectors
      ? flags?.isDirectorDetailsSet
      : !flags?.isDirectorDetailsSet) &&
    (flags?.isSignatorySkipped
      ? !flags?.isSignatorySet
      : flags?.isSignatorySet) &&
    flags?.isPinSet;

  return (
    <>
      {pathname.includes('global/set-up') ? (
        <Box color="#000" bg="#F6F6F9">
          {pathname.includes('global/set-up') && (
            <Flex
              alignItems="center"
              gap="1rem"
              top="0"
              left={0}
              right={0}
              pl="2rem"
              pr="4rem"
              justifyContent={{ base: 'start', md: 'end' }}
              pt={isMobile ? '1rem' : '2rem'}
              pb={isMobile ? 'unset' : '1.5rem'}
              zIndex={5}
              w="full"
              h="80px"
            >
              <Flex
                cursor="pointer"
                w="fit-content"
                color="#0F172B"
                onClick={() => navigate(-1)}
              >
                <IoIosArrowBack fontSize="26px" /> <Text>Back</Text>
              </Flex>
            </Flex>
          )}
          {pathname.includes('verify-kyc') && (
            <Flex
              alignItems="center"
              gap="1rem"
              pos="Fixed"
              top="0"
              left={0}
              right={0}
              pl="2rem"
              pt={isMobile ? '1rem' : '2rem'}
              pb={isMobile ? 'unset' : '1.5rem'}
              zIndex={5}
              bgColor="#ffff"
              // bgColor="#B4B4B463"
              borderBottom="1px solid #B4B4B463"
              w="full"
              h="100px"
            >
              {isMobile ? (
                <Flex
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => navigate(-1)}
                >
                  <IoIosArrowBack fontSize="20px" /> <Text>Back</Text>
                </Flex>
              ) : (
                <Flex
                  alignItems="center"
                  gap="1.6rem"
                  cursor="pointer"
                  onClick={() => navigate(-1)}
                >
                  <MdArrowBack fontSize="2rem" />
                  <Image src="/assets/logo.png" w="133px" objectFit="contain" />
                </Flex>
              )}
            </Flex>
          )}
          <Flex
            // minH="100vh"
            justifyContent="center"
            align="center"
            maxW="100%"
            px={!isPhone ? 'unset' : '30px'}
          >
            {children}
          </Flex>
        </Box>
      ) : (
        <Box
          overflowX={isMobile ? 'hidden' : ''}
          color="#000"
          fontFamily="Poppins"
          bg={pathname.includes('global-account') ? '#F6F6F9' : '#FCFCFC'}
          position="relative"
        >
          <Box minH="100vh" overflowX="hidden">
            <Header />

            {!isMobile && (
              <Box>
                {(user?.data?.userType === 'UNREGISTERED' &&
                  user?.data?.business?.businessType === 'BUSINESS-NAME' &&
                  !unregBusCheck) ||
                (account_type &&
                  account_type.includes('Don’t') &&
                  bus_account_type &&
                  bus_account_type.includes('business')) ? (
                  <BuseinssSideBar flags={flags} />
                ) : (user?.data?.userType === 'REGISTERED' && !regCheck) ||
                  (account_type &&
                    account_type.includes('Business') &&
                    !bus_account_type) ? (
                  <SetupSideBar flags={flags} />
                ) : (user?.data?.userType === 'UNREGISTERED' &&
                    user?.data?.business?.businessType ===
                      'LIMITED-LIABILITY-COMPANY' &&
                    !llcChecks) ||
                  (account_type &&
                    account_type.includes('Don’t') &&
                    bus_account_type &&
                    bus_account_type.includes('llc')) ? (
                  <LLCSideBar flags={flags} />
                ) : (user?.data?.userType === 'UNREGISTERED' &&
                    user?.data?.business?.businessType === 'NGO' &&
                    !ngoChecks) ||
                  (account_type &&
                    account_type.includes('Don’t') &&
                    bus_account_type &&
                    bus_account_type.includes('ngo')) ? (
                  <NGOSideBar flags={flags} />
                ) : (user?.data?.userType === 'FREELANCE' &&
                    !freelancerChecks) ||
                  (account_type && account_type.includes('Freelance')) ? (
                  <FreelancerSideBar flags={flags} />
                ) : (
                  <SideBar />
                )}
              </Box>
            )}

            <Box
              w="100%"
              pt={isMobile ? '120px' : '125px'}
              minH="100vh"
              color="#828282"
              pl={!isMobile ? '340px' : '20px'}
              pr={!isMobile ? '50px' : '20px'}
              pb={10}
            >
              {children}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export const NonAuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 1226px)');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Box
      color="#000"
      fontFamily="Poppins"
      bg={pathname.includes('/payment') ? '#F4F9FF' : '#ffff'}
    >
      {pathname.includes('payment') || pathname.includes('/invoice') ? (
        <Flex minH="100vh" flexDir="column" maxW="100%">
          <Box
            pos="Fixed"
            top={0}
            right={0}
            left={0}
            pl="2rem"
            pt="2rem"
            bgColor="#0F172B"
            w="full"
            h="100px"
          >
            <Image src="/assets/bizLogo.png" />
          </Box>
          <Box px="20px" mt="100px">
            {' '}
            {children}{' '}
          </Box>
        </Flex>
      ) : (
        <Flex
          minH="100vh"
          justifyContent="center"
          align="center"
          maxW="100%"
          px={
            !isMobile
              ? '58px'
              : pathname.includes('/invoice') || pathname.includes('/payment')
              ? '20px'
              : '30px'
          }
        >
          <Flex justifyContent="center" align="center" w="1256px" minH="100vh">
            <Box w="100%">
              <Box bg="#fff" zIndex={55} pos="sticky" top="0" py="20px">
                <Image
                  src="/assets/logo.png"
                  w="160px"
                  h="55px"
                  objectFit="contain"
                />
              </Box>

              <Flex
                flexDir="column"
                pb="30px"
                minH="80vh"
                w={
                  isMobile ||
                  pathname === '/invoice-preview' ||
                  pathname.includes('/payment')
                    ? '100%'
                    : '80%'
                }
              >
                {children}
                {pathname.includes('/login') && (
                  <Flex
                    mt="20px"
                    mb="50px"
                    fontSize="13px"
                    gap=".5rem"
                    justifyContent="center"
                  >
                    <Text color="rgba(26, 26, 26, 0.70)">
                      Don't have an account?{' '}
                    </Text>
                    <Text
                      color="#2463EB"
                      cursor="pointer"
                      onClick={() => navigate('/register-step-1')}
                      _hover={{ textDecor: 'underline' }}
                    >
                      Register
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Box>
            {pathname.includes('/invoice') || pathname.includes('/payment')
              ? ''
              : !isMobile && <SideBarr />}
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
