import { useMutation, useQuery } from 'react-query';
import { getAllTransactions, getRecentTransactions } from '../api/transactions';

export const useGetRecentTransactions = (
  options = {},
  page = '',
  limit = '',
  startDate = '',
  endDate = '',
  type = ''
) => {
  const { data, isLoading, refetch } = useQuery(
    ['getRecentTransactions', page, limit, startDate, endDate, type],
    getRecentTransactions,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetAllTransactions = (
  id = '',
  page = '',
  limit = '',
  startDate = '',
  endDate = '',
  type = '',
  category = '',
  reference = '',
  status = '',
  options = {}
) => {
  const { data, isLoading, refetch } = useQuery(
    [
      'getAllTransactions',
      id,
      page,
      limit,
      startDate,
      endDate,
      type,
      category,
      reference,
      status,
    ],
    getAllTransactions,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};
